.trainer-form-container.MuiContainer-root {
  margin: 2rem 0;
}

.trainer-form-container form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.heading.MuiTypography-root {
  font-size: 2rem;
}

.trainer-form-button.MuiButton-root {
  width: 10rem;
  align-self: flex-end;
}
