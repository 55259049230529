.container {
  width: 100vw;
  height: 100vh;
}

.container {
  width: 100vw;
  height: 90vh;
}

.logo-image {
  height: 30%;
}
.logo-image-div {
  display: flex;
  justify-content: center;
  padding: 10px;
  position: relative;
  top: 40%;
}
.logo-image img {
  width: 30%;
}
.login-container {
  height: 70%;
  display: flex;
  align-items: center;
}
.login-container form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 60%;
  padding: 2rem;
  position: relative;
  top: 10%;
  left: 20%
}
/* .login-container.MuiContainer-root {
  padding: 4rem 20%;
}

.login-container form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
}

.login-header.MuiTypography-root {
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  margin: 1rem 0;
}

@media screen and (max-width: 600px) {
  .login-container.MuiContainer-root {
    padding: 4rem 2rem;
  }
} */
@media screen and (max-width: 600px) {
  .login-container.MuiContainer-root {
    padding: 4rem 2rem;
  }
}
