.text-editor{
    margin-bottom: "50px" !important;
}

.ql-editor{
    height: "5rem" !important;   
}
.ql-container{
    min-height: 100px !important;
    max-height: 300px !important;
    overflow-y: scroll;
    border-radius: 0rem 0rem 1.5rem 1.5rem !important;    
}

.ql-container::-webkit-scrollbar-thumb {
    background: var(--primary-color);
    border-radius: 48px;
  }
  .ql-container::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  .ql-container::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

.ql-container.ql-snow{
    border: 1px solid #F77433 !important;
}

.ql-toolbar.ql-snow{
    border: 0px !important;
    background-color: #F77433 !important;
    border-radius: 1.5rem 1.5rem 0rem 0rem !important;
}