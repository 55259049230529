.heading.MuiTypography-root {
  font-size: 2rem;
  margin-top: 20px;
}
.plan-detail-head.MuiTypography-root {
  font-size: 1.5rem;
}
.sub-contain {
  padding: 10px;
  margin-top: 10px;
}

.outer-box {
  margin: 20px;
  padding: 20px;
  
}

.submit-btn{
    width: 40%;
    justify-items: center;
   
}
.success-box {
  margin: 20px;
  padding: 20px;
  height: 55vh;
}
.success-head.MuiTypography-root{
  font-size: 1.5rem;
  display: flex;
  justify-content: center;

  color: rgb(23, 148, 23);
}
.back-btn{
    width: 10%;   
}
.image{
  width: 25%;
}

