.userview-container.MuiContainer-root {
  height: 80vh;
  padding: 1rem;
}
.badge{
  font-weight: bold;
  position: absolute;
  left: 0px;
  top: 5px;
  transform: rotate(-45deg);
  color: #000;
  background-color: #000;
  -webkit-transform-origin-x: -34px;
  -webkit-transform-origin-y: 137px;
  padding-top: 2.5rem;
  padding-left: 1.9rem;
  padding-right: 1.5rem;
  padding-bottom: 0.1rem;
  font-size: 0.6rem;
}

.userview-navbar.MuiGrid-root {
  margin: 1rem 0;
  align-items: center;
  justify-content: space-between;
}

.active-status.MuiSvgIcon-root {
  color: #86b300;
}

.inactive-status.MuiSvgIcon-root {
  color: #cc0000;
}
.nav-button {
  width : 100%
}
