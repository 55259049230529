.stats.MuiContainer-root {
  padding: 2rem 1rem;
}

.stats-header.MuiTypography-root {
  font-size: 2rem;
}
.stats-detailHead.MuiTypography-root {
  font-size: 1.5rem;
}


.stats-container.MuiContainer-root {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0;
  margin: 1rem 0;
}

.stat-card {
  width: 30%; 
}

.stat-label.MuiTypography-root {
  color: #383838;
}
.show-Head.MuiTypography-root {
  color: #383838;
  font-size: 20px;
  font-weight: bold;
}


.show-value.MuiTypography-root {
  color: #acacac;
}

.stat-value.MuiTypography-root {
  font-size: 2rem;
  color: #acacac;
}
.stat-value-cur.MuiTypography-root {
  font-size: 1rem;
  color: #acacac;
}

.loading-spinner.MuiCircularProgress-root {
  margin: 10rem auto;
}
