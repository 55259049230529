.monitoring-container.MuiContainer-root {
  margin: 1rem auto;
}

.monitoring-container .MuiTabPanel-root {
  padding: 0;
}

.tablist.MuiTabs-root,
.tablist .MuiTabs-scroller {
  overflow: auto !important;
}

.chart-container.MuiContainer-root {
  margin: auto;
}


.chart-container canvas {
  width: 100%;
}

@media screen and (max-width: 600px) {
  .chart-container.MuiContainer-root {
    margin: 4rem 0.5rem;
    padding: 1rem 0.2rem;
  }
}

