.trainer-certificates-container.MuiContainer-root {
  height: 80vh;
  padding: 1rem;
}

.trainer-certificate-navbar.MuiContainer-root {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.add-certificate-container {
  margin: 2rem 0;
}


.add-certificate-container form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 50vh;
}

.add-certificate-title.MuiTypography-root {
  font-size: 2rem;
  margin: 1rem auto;
  text-align: center;
}
