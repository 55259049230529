.package-form-container.MuiContainer-root {
  margin: 2rem 0;
}

.package-form-container form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0rem 5rem;
}

.heading.MuiTypography-root {
  font-size: 2rem;
}

.package-form-button.MuiButton-root {
  margin-top: 1rem;
  width: 15rem;
  align-self: center;
}

.title.MuiTypography-root {
  font-size: 1rem;
  margin: 1rem 0;
}
.date_title.MuiTypography-root {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.css-1yd42wq-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: #f39b60 !important;
  color: #ffffff;
}
